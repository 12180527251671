<template>
  <div class="login-page">
    <div class="login-page__wrapper">
      <div class="login-page__img">
        <img src="img/logo.svg" class="logo_desktop" />
        <img src="img/logo_auth_mobile.svg" class="logo_mobile" />
      </div>
      <div class="login-page__content">
        <div class="login-page__title"><strong>REGISTRATION</strong></div>
        <form class="login-form">
          <div class="login-form__input">
            <input
              class="login-form__input-item"
              type="text"
              placeholder="Email"
              v-model="username"
              @input="validateWithErrors"
            />
          </div>
          <div class="login-form__input">
            <input
              class="login-form__input-item"
              v-model="password"
              v-bind:type="password_field_type"
              placeholder="Password"
              @input="validateWithErrors"
            />
            <a class="login-form__link" href="#" @click.prevent="toggle_password_field"><img src="/img/eye.svg" width="20"></a>
            <!-- <a class="login-form__link" href="#">Forgot</a> -->
          </div>

          <div class="checkbox activity-block__checkbox" style="margin-top: 25px;">
            <label class="checkbox__label">
              <input
                class="checkbox__input"
                v-model="checkbox"
                id="checkbox"
                type="checkbox"
              >
              <div class="checkbox__checkmark"></div>
              <div class="checkbox__content" style="color: white;">
                I Agree to&nbsp;
                <a
                  style="color: #33a0ff; text-decoration: underline;"
                  href="https://trident-guard.com/privacy-policy-trident-peer-to-peer-marketplace.html"
                >
                  Privacy Policy
                </a>
              </div>
            </label>
          </div>

          <!-- <div style="display: flex;">
            <input
              class="checkbox__input"
              id="checkbox"
              v-model="checkbox"
              type="checkbox"
            />
            <div class="checkbox__checkmark" @click="() => checkbox = !checkbox"></div>
            <label for="checkbox" class="checkbox__label">
              I Agree to&nbsp;
              <a class="checkbox__content" href="https://trident-guard.com/privacy-policy-trident-peer-to-peer-marketplace.html">
                Privacy Policy
              </a>
            </label>
          </div> -->
          <p class="login-form__error">
            <span  v-for="error in curr_errors" :key="error">
              {{ error }}
            </span>
          </p>
          <div class="login-form__submit">
            <input
              class="btn btn-yellow login-form__submit-item"
              type="button"
              value="SIGN UP"
              v-bind:class="{
                'btn-disabled': !validateEmail(username) || !validatePassword(password) || !checkbox
                }"
              @click="signup"
            />
          </div>
        </form>
      </div>
      <div class="login-page__footer">
        <a class="btn btn-blue login-page__sign-in" @click="signin"> SIGN IN </a>
        <a class="login-page__link" href="https://trident-guard.com">trident-guard.com</a>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'Registration',
  data() {
    return {
      username: '',
      password: '',
      checkbox: false,
      errors: {
        password_length: 'Password must contain at least 8 characters',
        email_valid: 'Email must be valid',
      },
      curr_errors: [],
      password_field_type: 'password',
    };
  },
  methods: {
    toggle_password_field() {
      if (this.password_field_type == 'password') {
        this.password_field_type = 'text';
      } else {
        this.password_field_type = 'password';
      }
    },
    signup() {
      if (!this.validateEmail(this.username)) {
        this.$root.snackbar.warn('Please enter a valid email address');
        return false;
      }
      if (!this.validatePassword(this.password)) {
        this.$root.snackbar.warn('Password must be at least 8 characters');
        return false;
      }

      if (this.$http?.defaults?.headers?.common?.Authorization) delete this.$http.defaults.headers.common.Authorization;

      this.$http.post(`${process.env.VUE_APP_PUBLIC_PREFIX}/registration`, {
        email: this.username.toLowerCase(),
        password: this.password,
        language: process.env.VUE_APP_DEFAULT_LOCALE.toUpperCase(),
      })
        .then((response) => {
          switch (response.status) {
            case 200:
              this.$root.snackbar.warn('No created new account');
              break;
            case 201:
              this.$root.snackbar.info('Created new account');
              break;
            default:
              this.$root.snackbar.warn(response.data);
          }
          this.$store.dispatch('auth/setEmail', this.username.toLowerCase());
          this.$store.dispatch('auth/setPassword', this.password);
          this.$root.snackbar.info('Code sent to your email');
          this.$router.push({ path: '/activate', params: { email: this.username.toLowerCase(), password: this.password } });
        })
        .catch((error) => {
          if (error.response) {
            switch (error.response.status) {
              case 400:
                this.$root.snackbar.warn('Invalid email');
                break;
              case 429:
                this.$root.snackbar.warn(error.response.data);
                break;
              default:
                this.$root.snackbar.warn(error.response?.data ? error.response.data : 'Wrong email or password!');
            }
            console.log(error.response);
          } else if (error.request) {
            this.$root.snackbar.warn(error.request);
            console.log(error.request);
          } else {
            this.$root.snackbar.warn(error.message);
          }
        });
      return true;
    },
    signin() {
      this.$router.push({ path: '/login' });
    },
    validateEmail(input) {
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(input)) {
        return true;
      }
      return false;
    },
    validatePassword(input) {
      if (input.length >= 8) {
        return true;
      }
      return false;
    },
    validateWithErrors: _.debounce(function () {
      this.curr_errors = [];
      if (this.password.length > 0 && this.username.length > 0) {
        Object.keys(this.errors).filter((error) => {
          if (!this.validatePassword(this.password) && error === 'password_length') {
            this.curr_errors.push(this.errors[error]);
          }
          if (!this.validateEmail(this.username) && error === 'email_valid') {
            this.curr_errors.push(this.errors[error]);
          }
          return true;
        });
      }
    }, 750),
  },
  mounted() {
    // this.$emit('snackbar', 'message');
  },
};
</script>
